import './FamilyTree.css';
import {Component} from "react";
import {getDatabase, ref, onValue} from "firebase/database";
import {initializeApp} from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBVLyNqbL1XqPiDhRnSpBbMfw-VtWgIl_o",
    authDomain: "keresztirany-weblap.firebaseapp.com",
    databaseURL: "https://keresztirany-weblap-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "keresztirany-weblap",
    storageBucket: "keresztirany-weblap.appspot.com",
    messagingSenderId: "246336686346",
    appId: "1:246336686346:web:9fff9ecf456b7d710452f3",
    measurementId: "G-FWX0M9SVL9"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const familyTreeRef = ref(db, '/csaladfa/data');

function createTree(elements, name, first, last) {
    let childNodes = Object.keys(elements || {});
    let obj = <div className="node">
        <div className="string-horizontal">
            <div className={'left-string-horizontal' + (first ? ' hide ' : '')}/>
            <div className={'right-string-horizontal' + (last ? ' hide ' : '')}/>
        </div>
        <div className="string-vertical"></div>
        <div className="name">{name}</div>
        {(childNodes.length > 1) ? <div className="string-vertical-short"></div> : ''}
        <div className="child-nodes">
            {childNodes.map((item, index) => createTree(elements[item].childNodes, item, index === 0, index === (childNodes.length - 1)))}
        </div>
    </div>

    return obj;
}

class FamilyTree extends Component {
    constructor(props) {
        super(props);
        this.state = {data: {}};
    }

    componentDidMount() {
        onValue(familyTreeRef, (snapshot) => {
            const data = snapshot.val();
            this.setState({data});
        });
    }

    render() {
        let childNodes = Object.keys(this.state.data);
        return (<div className="App">
            <div className="first-node">
                <div className="name">Keresztirány</div>
                <div className="string-vertical-short"></div>
                <div className="child-nodes">
                    {childNodes.map((item, index) => createTree(this.state.data[item].childNodes, item, index === 0, index === (childNodes.length - 1)))}
                </div>
            </div>
        </div>);
    }
}

export default FamilyTree;
