import logo from './underconst.png';
import kilogo from './kilogo.svg'
import './App.css';
import {Component} from "react";
import FamilyTree from "./FamilyTree/FamilyTree";

class App extends Component {
    constructor(props) {
        super(props);
        this.familyTree = window.location.pathname.indexOf('/csaladfa') === 0;
    }

    render() {
        return (this.familyTree ?
            <FamilyTree/> :
            <div className="App">
                <header className="App-header">
                    <img src={kilogo} className="background" alt="logo"/>
                    <img src={logo} className="App-logo" alt="logo"/>
                </header>
            </div>);
    }
}

export default App;
